import http from "../http-common";
import md5 from "md5";

const requestContact = async (data) => {
  return await http
    .post("/marketing/requestContact", {
      phone: data.phone,
      ddi: data.ddi,
      recaptcha: data.recaptcha,
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
export default {
  requestContact
};
