<template>
  <div ref="component">
    <v-tabs v-model="tab" color="primary" grow class="rounded-t-lg">
      <v-tab v-for="(item, i) in items" :key="i">
        <v-icon left>{{ item.icon }}</v-icon> {{ item.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="rounded-b-lg">
      <v-tab-item v-for="item in items" :key="item.title">
        <div class="ma-4 mx-2">
          <cols-organizer :items="item.features" :cols="1" :sm="2" :md="3">
            <template v-slot="{ item }">
              <v-card outlined>
                <v-img v-if="item.image" :src="item.image" contain />
                <div class="pa-5 py-3">
                  <h6 class="mb-0">{{ item.title }}</h6>
                  <p class="mb-0">{{ item.description }}</p>
                </div>
              </v-card>
            </template>
          </cols-organizer>
        </div>
      </v-tab-item>
    </v-tabs-items>

    <div class="d-flex justify-center align-center mt-2">
      <v-btn small @click="goToNext" text>
        ir para
        <v-icon class="px-2" small>
          {{ items[(tab + 1) % items.length].icon }}
        </v-icon>
        {{ items[(tab + 1) % items.length].title }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import ColsOrganizer from "../global/ColsOrganizer.vue";
export default {
  components: { ColsOrganizer },
  data: () => ({
    tab: 0,
    items: [
      {
        id: "security",
        title: "Segurança",
        icon: "mdi-shield-account",
        features: [
          {
            title: "Validação de Ingressos Robusta",
            description:
              "Garanta a autenticidade dos ingressos com nossa robusta validação, evitando fraudes e falsificações.",
            image: require("../../assets/images/home/features/security_ticket.png"),
          },
          {
            title: "Acesso Restrito a Eventos Privados",
            description:
              "Mantenha seus eventos privados seguros, permitindo apenas convidados autorizados a comprar ingressos.",
          },
          {
            title: "Combate Eficaz a Cambistas",
            description:
              "Utilize nossas técnicas anti-cambistas para proteger seu evento e garantir a venda justa de ingressos.",
          },
        ],
      },
      {
        id: "control",
        title: "Controle",
        icon: "mdi-account-cog",
        features: [
          {
            title: "Opções Flexíveis de Venda de Ingressos",
            description:
              "Defina como e por quem seus ingressos serão vendidos, seja online ou por promotores.",
          },
          {
            title: "Gerencie Permissões da Organização",
            description:
              "Controle as permissões de cada membro da equipe para uma gestão eficiente e organizada.",
          },
          {
            title: "Defina as Regras de Reembolso e Transferência de Ingressos",
            description:
              "Estabeleça suas próprias regras de reembolso para maior flexibilidade e satisfação do cliente.",
          },
        ],
      },
      {
        id: "information",
        title: "Informação",
        icon: "mdi-information",
        features: [
          {
            title: "Relatórios de Vendas Detalhados",
            description:
              "Acompanhe a performance de vendas com relatórios abrangentes e insights valiosos.",
            image: require("../../assets/images/home/features/reports_party.png"),
          },
          {
            title: "Controle Financeiro Eficiente",
            description:
              "Monitore suas finanças com relatórios precisos e mantenha-se atualizado sobre a saúde financeira do evento e organização.",
            image: require("../../assets/images/home/features/reports_financial.png"),
          },
          {
            title: "Acompanhe Débitos com Promotores",
            description:
              "Controle as vendas e repasses dos promotores, garantindo transparência e eficiência na gestão.",
            image: require("../../assets/images/home/features/reports_debits.png"),
          },
          {
            title: "Relatório Analytics",
            description:
              "Tenha acesso a um relatórios com os dados do Google Analytics diretamente no painel do evento.",
            image: require("../../assets/images/home/features/reports_analytics.png"),
          },
          {
            title: "Relatórios de Entrada",
            description:
              "Analise dados de entrada no evento para melhorar a experiência e planejar futuras edições.",
          },
        ],
      },
    ],
  }),
  methods: {
    goToNext() {
      const el = this.$refs.component;
      this.$vuetify.goTo(el, {
        offset: 30,
        behavior: "smooth",
      });
      const tab = (this.tab + 1) % this.items.length;
      this.tab = tab;
    },
    goTo(id) {
      var index = this.items.findIndex((item) => item.id === id);
      this.tab = index;
    },
  },
};
</script>

<style></style>
