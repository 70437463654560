<template>
  <div class="pa-4">
    <v-row>
      <v-col
        order="2"
        order-sm="0"
        cols="12"
        sm="6"
        md="7"
        class="text-center px-6"
      >
        <h3>Eleve seus eventos com TicketMe!</h3>
        <p>
          Otimize a gestão e venda de ingressos com o TicketMe. Desfrute de
          eficiência, acesso a informações e uma experiência superior para seu
          público. Tenha sucesso em seus eventos! Insira seu WhatsApp abaixo e
          nossa equipe entrará em contato para mostrar como o TicketMe pode
          transformar seus eventos.
        </p>

        <template v-if="success">
          <v-alert type="success" text> Mensagem enviada com sucesso! </v-alert>
        </template>
        <template v-else>
          <template v-if="error">
            <v-alert type="error" text> {{ error }} </v-alert>
          </template>

          <phone-input
            :disabled="loading"
            :phone="whatsapp.phone"
            @update:phone="(e) => (whatsapp.phone = e)"
            :ddi="whatsapp.ddi"
            @update:ddi="(e) => (whatsapp.ddi = e)"
            @valid="(v) => (valid = v)"
            dense
            outlined
          />
          <v-btn
            color="primary"
            class="mx-2"
            large
            :disabled="!valid"
            :loading="loading"
            @click="processRecaptcha"
          >
            Enviar
          </v-btn>
        </template>
        <re-captcha v-if="started" ref="recaptcha" @success="send" @error="recaptchaError" @close="recaptchaError" />
      </v-col>
      <v-col order="1" cols="12" sm="6" md="5">
        <div class="d-flex flex-column justify-center align-center h-full">
          <div :style="bgStyle" class="pa-12">
            <img
              width="100%"
              contain
              :src="require('@/assets/images/logo/logo-dark.png')"
              alt="TicketMe Logo"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PhoneInput from "../global/PhoneInput.vue";
import MARKETING from "@/services/marketing";
import ReCaptcha from "../global/ReCaptcha.vue";
export default {
  components: { PhoneInput, ReCaptcha },
  data: () => ({
    whatsapp: {
      ddi: 55,
      phone: "",
    },
    loading: false,
    success: false,
    error: false,
    valid: false,
    started: false,
  }),
  methods: {
    recaptchaError() {
      this.loading = false;
      this.error = "Erro ao validar recaptcha.";
    },
    processRecaptcha() {
      this.loading = true;
      this.error = false;
      this.$refs.recaptcha.execute();
    },
    async send(recaptcha) {
      try {
        this.loading = true;
        await MARKETING.requestContact({ ...this.whatsapp, recaptcha });
        this.success = true;
      } catch (e) {
        this.error = e.message || "Erro ao enviar mensagem.";
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "whatsapp.phone"() {
      this.started = true;
    },
  },
  computed: {
    bgStyle() {
      return {
        background: `radial-gradient(ellipse at center, #304156 5%, transparent 70%)`,
      };
    },
  },
};
</script>

<style></style>
