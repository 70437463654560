<template>
  <div
    class="rounded-lg mx-5 my-2"
    style="position: relative"
    v-if="photos.length"
  >
    <h3 class="text-center">Fotos em destaque</h3>
    <v-carousel
      v-model="index"
      hide-delimiters
      :show-arrows="true"
      :show-arrows-on-hover="true"
      height="auto"
      :interval="2500 + 1000 * photosPerSlide"
      cycle
    >
      <v-carousel-item
        v-for="i in Math.floor(mappedPhotos.length / photosPerSlide)"
        :key="i"
        class="rounded-xl"
      >
        <v-row dense class="mx-0">
          <v-col
            v-for="photo in mappedPhotos.slice(
              (i - 1) * photosPerSlide,
              i * photosPerSlide
            )"
            :key="photo.id"
          >
            <v-card
              @click="goToPartyGallery(photo.Party)"
              class="relative rounded-xl"
            >
              <v-img
                class="rounded-xl"
                :src="photo.url"
                :lazy-src="photo.lazySrc"
                :aspect-ratio="0.9"
                style="max-height: 65vh"
              >
                <div
                  class="d-flex flex-column h-full pa-4 justify-space-between rounded-xl"
                  :style="{
                    width: `100%`,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    background: `linear-gradient(
                  0deg,
                  transparent 65%,
                  rgba(0, 0, 0, 1) 100%
                )`,
                  }"
                >
                  <div class="d-flex gap-2 justify-space-between">
                    <div class="d-flex flex-column">
                      <h6 class="white--text mb-0">{{ photo.Party.name }}</h6>
                      <small class="white--text">
                        {{ photo.Party.Organization.name }}
                      </small>
                    </div>
                    <div >
                      <v-img
                        :src="photo.Party.cover"
                        :width="(16 / 9) * 50"
                        :height="50"
                        class="rounded-lg"
                        @click="goToPartyGallery(photo.Party)"
                      />
                    </div>
                  </div>
                </div>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
    <v-btn text large class="mt-1" block to="/gallery">
      Ver todas as galerias
    </v-btn>
  </div>
</template>

<script>
import GALLERY from "@/services/app/gallery";

export default {
  data() {
    return {
      index: 0,
      photos: [],
    };
  },
  methods: {
    async getPhotos() {
      const { photos } = await GALLERY.highlight();
      this.photos = photos;
    },
    formatUrl(url, quality) {
      return url.replace(/raw$/, quality);
    },
    goToPartyGallery(party) {
      this.$router.push({
        name: "shop.party.gallery",
        params: {
          orgSlug: party.Organization.slug,
          partyId: party.slug || party.id,
        },
      });
    },
  },
  computed: {
    photosPerSlide() {
      if (this.$vuetify.breakpoint.smAndDown) return 1;
      if (this.$vuetify.breakpoint.mdAndDown) return 2;
      if (this.$vuetify.breakpoint.lgAndDown) return 3;
      return 4;
    },
    mappedPhotos() {
      return this.photos.map((item) => ({
        ...item,
        url: item.url || item,
        preview: this.formatUrl(item.url || item, "medium"),
        lazySrc: this.formatUrl(item.url || item, "lazy"),
      }));
    },
  },
  mounted() {
    this.getPhotos();
  },
};
</script>

<style></style>
