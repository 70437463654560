<template>
  <div class="pa-8">
    <h1 class="w-full text-center">Revolucionando a experiência em eventos!</h1>
    <v-row>
      <v-col v-for="(pillar, i) in pillars" :key="i">
        <v-card class="rounded-xl" outline @click="goTo(pillar.goToId)">
          <v-card-text class="d-flex flex-column align-center">
            <v-icon x-large class="mr-2">{{ pillar.icon }}</v-icon>
            <h3>{{ pillar.title }}</h3>
            <p class="text-center">{{ pillar.description }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VideoBackground from "vue-responsive-video-background-player";

export default {
  components: { VideoBackground },
  methods: {
    goTo(id) {
      this.$emit("goTo", { hash: id });
    },
  },
  data: () => ({
    pillars: [
      {
        title: "Segurança",
        description:
          "Desfrute de recursos avançados de segurança, garantindo proteção e confiabilidade nas transações de ingressos.",
        icon: "mdi-shield-account",
        goToId: "security",
      },
      {
        title: "Controle",
        description:
          "Gerencie todos os aspectos do seu evento com facilidade, desde a venda de ingressos até a organização geral.",
        icon: "mdi-account-cog",
        goToId: "control",
      },
      {
        title: "Informação",
        description:
          "Acesse dados valiosos e insights úteis para aprimorar continuamente a experiência do seu evento e público.",
        icon: "mdi-information",
        goToId: "information",
      },
    ],
  }),
};
</script>

<style></style>
