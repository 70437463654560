var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.photos.length)?_c('div',{staticClass:"rounded-lg mx-5 my-2",staticStyle:{"position":"relative"}},[_c('h3',{staticClass:"text-center"},[_vm._v("Fotos em destaque")]),_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows":true,"show-arrows-on-hover":true,"height":"auto","interval":2500 + 1000 * _vm.photosPerSlide,"cycle":""},model:{value:(_vm.index),callback:function ($$v) {_vm.index=$$v},expression:"index"}},_vm._l((Math.floor(_vm.mappedPhotos.length / _vm.photosPerSlide)),function(i){return _c('v-carousel-item',{key:i,staticClass:"rounded-xl"},[_c('v-row',{staticClass:"mx-0",attrs:{"dense":""}},_vm._l((_vm.mappedPhotos.slice(
            (i - 1) * _vm.photosPerSlide,
            i * _vm.photosPerSlide
          )),function(photo){return _c('v-col',{key:photo.id},[_c('v-card',{staticClass:"relative rounded-xl",on:{"click":function($event){return _vm.goToPartyGallery(photo.Party)}}},[_c('v-img',{staticClass:"rounded-xl",staticStyle:{"max-height":"65vh"},attrs:{"src":photo.url,"lazy-src":photo.lazySrc,"aspect-ratio":0.9}},[_c('div',{staticClass:"d-flex flex-column h-full pa-4 justify-space-between rounded-xl",style:({
                  width: `100%`,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  background: `linear-gradient(
                0deg,
                transparent 65%,
                rgba(0, 0, 0, 1) 100%
              )`,
                })},[_c('div',{staticClass:"d-flex gap-2 justify-space-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('h6',{staticClass:"white--text mb-0"},[_vm._v(_vm._s(photo.Party.name))]),_c('small',{staticClass:"white--text"},[_vm._v(" "+_vm._s(photo.Party.Organization.name)+" ")])]),_c('div',[_c('v-img',{staticClass:"rounded-lg",attrs:{"src":photo.Party.cover,"width":(16 / 9) * 50,"height":50},on:{"click":function($event){return _vm.goToPartyGallery(photo.Party)}}})],1)])])])],1)],1)}),1)],1)}),1),_c('v-btn',{staticClass:"mt-1",attrs:{"text":"","large":"","block":"","to":"/gallery"}},[_vm._v(" Ver todas as galerias ")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }